import {
    Alert, Avatar,
    Box, Button,
    Checkbox, Chip, CircularProgress,
    Container, Dialog, DialogActions, DialogContent,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    styled, Toolbar,
    Typography
} from "@mui/material";
import {extractTrackingCookie, generateRequestId, getSearchResults, SearchResponseFacetCounts} from "./api-client";
import {useEffect, useState} from "react";

export interface Facet {
    name: string
    values: [string]
}

export interface DialogProperties {
    accountId:  string
    domainKey: string
    environment: string
    viewId?: string
    onSelected: (values: Map<string, Set<string>>, facets: string[]) => void
    defaultValues: { values: Map<string, Set<string>>, facets: string[] }
    view: string
}

export const VIEW = {
    FACETS_ONLY: "FACET_ONLY",
    VALUES_ONLY: "VALUES_ONLY",
    ALL: "ALL"
}

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: '#002840',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'left',
    color: '#fff',
}));

export const CMSDialog = ({
                              accountId,
                              domainKey,
                              environment,
                              viewId,
                              defaultValues,
                              onSelected,
                              view
                          }: DialogProperties) => {

    const [loading, setLoading] = useState(false)
    const [responseFacets, setResponseFacets] = useState<SearchResponseFacetCounts>()
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [facetValues, setFacetValues] = useState(new Map<string, Set<string>>(defaultValues.values))
    const [facets, setFacets] = useState<string[]>(defaultValues.facets)

    const setFacetValue = (key: string, value: string, enabled: boolean) => {
        const values = new Map(facetValues);

        if (!values.has(key)) {
            values.set(key, new Set<string>());
        }
        const set = values.get(key);
        if (enabled) {
            set.add(value);
        } else {
            set.delete(value);
            if (set.size == 0) {
                values.delete(key)
            }
        }
        setFacetValues(values);
    };


    useEffect(() => {
        setLoading(true);

        getSearchResults({
            endpoint: environment === 'STAGING' ? 'https://staging-core.dxpapi.com/api/v1/core/' : 'https://core.dxpapi.com/api/v1/core/',
            _br_uid_2: extractTrackingCookie(),
            account_id: stringToNumber(accountId ?? '', 0),
            domain_key: domainKey,
            q: "*",
            request_id: generateRequestId(),
            request_type: 'search',
            search_type: 'keyword',
            start: 0,
            rows: 0,
            view_id: viewId,
            url: `${window.location.href}`,
        }).then(result => {
            setResponseFacets(result?.facet_counts)
            setLoading(false)
        }).catch(reason => setErrorMessage(reason.toString()))
    }, [accountId, domainKey, environment, viewId])


    if (errorMessage) {
        return (
            <Container>
                <Alert variant={"outlined"} color={"error"}>{errorMessage}</Alert>
            </Container>
        )
    }

    if (loading) {
        return (
            <Container>
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box>
            </Container>
        )
    }


    return (
        <Dialog open fullScreen>
            <DialogContent>
                <Box sx={{flexGrow: 1}}>
                    <Grid container spacing={2}>
                        {responseFacets && Object.entries(responseFacets.facet_fields).filter(value => value[1].length > 0).sort((a, b) => {
                            const [nameA] = a
                            const [nameB] = b
                            const propA = nameA.toString().toLowerCase();
                            const propB = nameB.toString().toLowerCase();
                            return propA.localeCompare(propB);
                        }).map(value => {
                            const [name, values] = value
                            return (<Grid key={name} item xs={4} md={3} lg={2}>
                                <Box height={[VIEW.ALL, VIEW.VALUES_ONLY].includes(view) ? '250px' : 'auto'}
                                     overflow={'auto'}
                                     position={'relative'} border={'2px solid #ffd500'} borderRadius={'5px'}>
                                    <Item sx={{
                                        position: 'sticky',
                                        width: '100%',
                                        top: 0,
                                        zIndex: 999
                                    }}>{[VIEW.ALL, VIEW.FACETS_ONLY].includes(view) ? <FormControlLabel
                                            control={<Checkbox
                                                checked={facets.includes(name)}
                                                onChange={event => {
                                                    const checked = event.target.checked;
                                                    if (checked) {
                                                        setFacets([...facets, name])
                                                    } else {
                                                        setFacets(facets.filter(value1 => value1 !== name))
                                                    }
                                                }}
                                                sx={{
                                                    color: "white"
                                                }}/>}
                                            label={name}/> :
                                        <Typography>{name}</Typography>}</Item>
                                    {[VIEW.ALL, VIEW.VALUES_ONLY].includes(view) && <Box padding={1} color={'#002840'}>
                                        <FormGroup>
                                            {values && values.map((facetValue, index) => {

                                                const value = facetValue.name ?? facetValue.cat_id
                                                let checked = false;
                                                if (facetValues.has(name)) {
                                                    checked = facetValues.get(name).has(value)
                                                }

                                                return (
                                                    <FormControlLabel key={index}
                                                                      label={facetValue.name ?? facetValue?.cat_name}
                                                                      control={<Checkbox
                                                                          checked={checked}
                                                                          onChange={event => setFacetValue(name, value, event.target.checked)}
                                                                      />}/>)
                                            })}
                                        </FormGroup>
                                    </Box>}
                                </Box>
                            </Grid>)
                        })}
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{flex: 7, textAlign: 'left'}}>
                    {Array.from(facetValues).map(([key, set]) => {
                        return Array.from(set).map(value => {
                            return <Chip key={`${key}-${value}`}
                                         size={'small'}
                                         label={`${key}:${value}`}
                                         onDelete={event => setFacetValue(key, value, false)}
                            />
                        })
                    })}
                    {facets.map((facet) => {
                        return <Chip key={`${facet}`}
                                     size={'small'}
                                     label={`${facet}`}
                                     onDelete={event => setFacets(facets.filter(value1 => value1 !== facet))}
                        />

                    })}
                </Box>
                <Box style={{flex: 1, display: 'contents'}}>
                    <Button variant={"outlined"} sx={{marginRight: '15px'}} color="inherit"
                            onClick={() => onSelected(facetValues, facets)}>Ok</Button></Box>
            </DialogActions>
        </Dialog>
    )
}

export function stringToNumber(str: string, fallback?: number): number | undefined {
    const num = parseInt(str, 10);
    return isNaN(num) ? fallback : num;
}