import {Box, Button, Chip, Dialog, DialogActions} from "@mui/material";
import {useEffect, useState} from "react";

export interface Facet {
    name: string
    values: [string]
}

export interface FieldProperties {
    onDialogOpen: (values: Map<string, Set<string>>, facets: string[]) => { values: Map<string, Set<string>>, facets: string[] }
    onChange: (values: Map<string, Set<string>>, facets: string[]) => void
    onInitResize: () => void
    defaultValues: { values: Map<string, Set<string>>, facets: string[] }
    mode: string
}


export const Field = ({defaultValues,
                          onChange,
                          onDialogOpen,
                          mode,
                          onInitResize
                      }: FieldProperties) => {


    const [facetValues, setFacetValues] = useState(new Map<string, Set<string>>(defaultValues.values))
    const [facets, setFacets] = useState<string[]>(defaultValues.facets)

    useEffect(() => {
        onInitResize()
    }, [])


    const setFacetValue = (key: string, value: string, enabled: boolean) => {
        const values = new Map(facetValues);

        if (!values.has(key)) {
            values.set(key, new Set<string>());
        }
        const set = values.get(key);
        if (enabled) {
            set.add(value);
        } else {
            set.delete(value);
            if (set.size == 0) {
                values.delete(key)
            }
        }
        setFacetValues(values);
        onChange(values, facets);
    };


    return (
        // <Dialog open fullScreen>
        //     <DialogActions>
        <Box display={'flex'} flex={'0 0 auto'} alignItems={'center'} paddingY={2}>
            <Box sx={{flex: 7, textAlign: 'left'}}>
                {facetValues && Array.from(facetValues).map(([key, set]) => {
                    return Array.from(set).map(value => {
                        return <Chip key={`${key}-${value}`}
                                     size={'medium'}
                                     disabled={mode !== 'edit'}
                                     label={`${key}:${value}`}
                                     onDelete={() => setFacetValue(key, value, false)}
                        />
                    })
                })}
                {facets && facets.map((facet) => {
                    return <Chip key={`${facet}`}
                                 size={'medium'}
                                 label={`${facet}`}
                                 disabled={mode !== 'edit'}
                                 onDelete={() => {
                                     const newFacets = facets.filter(value1 => value1 !== facet)
                                     setFacets(newFacets)
                                     onChange(facetValues, newFacets)
                                 }}
                    />

                })}
            </Box>
            <Box style={{flex: 1, display: mode === 'edit' ? 'contents' : 'none'}}>
                <Button variant={"outlined"} sx={{marginRight: '15px'}} color="inherit"
                        onClick={async () => {
                            const response = await onDialogOpen(facetValues, facets)

                            setFacets(response.facets)
                            setFacetValues(response.values)
                            onChange(response.values, response.facets)
                        }}>Select</Button></Box>
            {/*//     </DialogActions>*/}
            {/*// </Dialog>*/}
        </Box>
    )

}


