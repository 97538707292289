import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import UiExtension, {DialogSize, UiScope} from '@bloomreach/ui-extension-saas';
import {CMSDialog, VIEW} from './CMSDialog';
import {Field} from './Field';
import {CircularProgress} from '@mui/material';

async function render() {
    const root = ReactDOM.createRoot(document.getElementById('root'));

    try {
        const ui: UiScope = await UiExtension.register();

        let mode = 'view';
        let value = {values: {}, facets: []};
        let config: {
            accountId?: string;
            domainKey?: string;
            environment?: string;
            viewId?: string;
            view: string;
        } = {view: VIEW.VALUES_ONLY};

        try {
            const document = await ui.document.get();
            mode = document.mode;
        } catch (error) {
        }

        try {
            value = JSON.parse(await ui.document.field.getValue());
        } catch (error) {
        }

        try {
            const dialog = await ui.dialog.options();
            value = JSON.parse(dialog.value);
        } catch (error) {
        }

        try {
            config = JSON.parse(ui.extension.config);
        } catch (error) {
        }

        try {
            const channel = await ui.channel.get();
            config = {
                ...config,
                accountId: channel.discoveryAccountId,
                domainKey: channel.discoveryDomainKey,
                environment: channel.discoveryRealm,
                viewId: channel.discoveryViewId,
            };
        } catch (error) {
        }

        root.render(
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={
                        <Field
                            onInitResize={() => {
                                ui.document.field.setHeight('auto');
                            }}
                            onChange={async (values, facets) => {
                                const store = {values: mapToObject(values), facets};
                                await ui.document.field.setValue(JSON.stringify(store));
                                await ui.document.field.setHeight('auto');
                            }}
                            mode={mode}
                            //@ts-ignore
                            onDialogOpen={async (values, facets) => {
                                const dialogOptions = {
                                    title: 'Discovery Facets Picker',
                                    url: './dialog',
                                    size: DialogSize.Large,
                                    value: JSON.stringify({values: mapToObject(values), facets}),
                                };

                                let responseState;
                                try {
                                    const response = await ui.dialog.open(dialogOptions);
                                    //@ts-ignore
                                    responseState = JSON.parse(response);
                                } catch {
                                }

                                return {values: objectToMap(responseState.values), facets: responseState.facets};
                            }}
                            defaultValues={{values: objectToMap(value.values), facets: value.facets}}
                        />}/>
                    <Route path="/dialog"
                           element={<CMSDialog accountId={config.accountId} domainKey={config.domainKey}
                                               environment={config.environment} viewId={config.viewId}
                                               onSelected={async (values, facets) => {
                                                   const store = {values: mapToObject(values), facets};
                                                   await ui.dialog.close(JSON.stringify(store));
                                               }}
                                               defaultValues={{values: objectToMap(value.values), facets: value.facets}}
                                               view={config.view}
                           />}/>
                </Routes>
            </BrowserRouter>
        );
    } catch (error) {
        root.render(
            <CircularProgress/>
        );

        console.log(error);
        console.error('Failed to register extension:', error.message);
        console.error('- error code:', error.code);
        console.error('dev mode..');

    }
}

render();

function mapToObject<K, V>(map: Map<K, V>): Record<string, V | any> {
    const obj: Record<string, V | any> = {};

    map.forEach((value, key) => {
        const parsedValue = value instanceof Set ? Array.from(value) : value;
        obj[key.toString()] = parsedValue;
    });

    return obj;
}

function objectToMap<K, V>(obj: Record<string, V>): Map<K, V | any> {
    const map = new Map<K, V | any>();

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = Array.isArray(obj[key]) ? new Set(obj[key] as []) : obj[key];
            map.set(key as unknown as K, value);
        }
    }

    return map;
}
